<template>
<div style="width:100%;height:100%">
<div class="documentation">
    <div style="width: 24%;background: rgb(242,242,242);">
    <div class="iotName">文档中心</div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-vertical-demo"
        :unique-opened="true"
        background-color="rgb(242,242,242)"
        text-color="#000"
        style="height:100%;font-size:16px"
        @select="getBox"
      >
        <DomList v-for="item in nameList" :key="item.workId" :data="item">
        </DomList>
      </el-menu>
    </div>
    <div style="width: 76%; background: rgb(245,246,248);">
      <iframe
        v-if="check == 0"
        :src="url"
        width="100%"
        height="100%"
        frameborder="0"
      >
      </iframe>
      <div v-if="check == 1" class="iotName"></div>
      <div v-if="check == 1" class="videoBox">
        
        <div
          v-for="item in videsList"
          :key="item.workId"
          @click="videoDialog(item)"
          class="videoImg"
          
        >
            <div style="width:100%;height:180px;">
                <img :src="workUrl + item.workImg" style="width:100%;height:100%; border-radius: 12px;" alt="">
            </div>
            <div class="videoName" >{{item.name}}</div>
        </div>
      </div>
    </div>
    <el-dialog top="3%" :visible.sync="dialogVisible" :before-close="handleClose" width="60%" height="50%">
      <video :src="videoUrl" id="videos" width="100%" height="600px"  autoplay controls >您的浏览器不支持播放视频！</video>
    </el-dialog>
  </div>

</div>
  
</template>

<script>
import DomList from "../components/DocumentationList.vue";
import { workUrl } from "../util/global.js";
let that;
export default {
  components: { DomList },
  data() {
    return {
      dialogVisible: false,
      activeIndex: "154",
      nameList: [],
      // platformName:'',
      workUrl: workUrl,
      url: "",
      videsList: [],
      check: 0,
      videoUrl:"",
    };
  },
  mounted() {
    that = this;
    that.getALLWorkOrderLists();
  //  that.platformName= window.localStorage.getItem("platformName");
  },
  methods: {
    // 获取菜单列表
    getALLWorkOrderLists() {
      that.$api.workOrder.getALLWorkOrderLists().then((res) => {
        if (res.code == 200) {
          that.nameList = res.data;
          
          that.setActiveIndex(res.data[0]);
        }
      });
    },
    // 设置第一个选中
    setActiveIndex(list) {
      if (list.children.length > 0&&list.workType!=1) {
        that.setActiveIndex(list.children[0]);
      } else {
        that.activeIndex = list.workId + "-" + list.workType;
        that.check = list.workType;
        that.getBox(that.activeIndex);
      }
    },
    // 获取右侧说明或视频内容
    getBox(index) {
      that.url = "";
      let arr = index.split("-");
      that.check = arr[1];
      if (arr[1] == 0) {
        that.$api.workOrder.getWorkOrder(arr[0]).then((res) => {
          if (res.code == 200) {
              if (res.data.workOrder) {
                 that.url = workUrl + res.data.workOrder; 
              }else{
                  that.url =""
              }
            
          }
        });
      } else if (arr[1] == 1) {
        that.$api.workOrder.getWorkOrderList(arr[0]).then((res) => {
          if (res.code == 200) {
            that.videsList = res.data;
          }
        });
      }
    },
    videoDialog(item) {
    
        if (item.workOrder) {
            that.videoUrl=workUrl+item.workOrder;
            that.dialogVisible=true;
        let videos=document.getElementById("videos");
       videos.play();
        }else{
         that.$message({
              message: "没有找到视频！",
              type: "error",
            });
        }
      
    },
    handleClose(done){
      let videos=document.getElementById("videos");
       videos.pause();
       done();
      },
  },
};
</script>
<style scoped>
.documentation {
  width: 1075px;
  height:100% ;
  margin: 0 auto;
  display: flex;
}
::v-deep .el-dialog__header{
    height: 0;
}
.videoBox{
    width: 100%;
    max-height: calc(100% - 50px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 10px;
}
.videoBox::-webkit-scrollbar {
    width: 10px;
    /*对垂直流动条有效*/
    height: 10px;
    /*对水平流动条有效*/
}

/*定义滑块颜色、内阴影及圆角*/
.videoBox::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #AAAAAACC;
}

.title{
    width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 50px;
}
.videoName{
    /* text-align:center; */
    font-size: 14px;
    color: #666;
    box-sizing: border-box;
   font-weight: bold;
    padding:5px 10px ;
    line-height: 20px;
}
.iotName{
    width:100%;
    height:50px;
    text-align:center;
    font-size: 21px;
    color:#000;
    font-weight: bolder;
    line-height: 50px;
}
::v-deep .el-menu{
    border: none;
}
::v-deep .el-menu-item{
  height: 40px;
  line-height: 40px;
    font-size: 14px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 100px !important;
}
::v-deep .el-submenu__title{
  height: 40px;
  line-height: 40px;
    font-size: 14px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
   
}
.videoImg{
  width:378px;
  height:210px;
  margin:7px;
  cursor: pointer;
  border-radius: 12px;
  background: #ffffff;
}
.videoImg:hover{
  box-shadow: #ddd 0px 0px 5px 5px;
}
::v-deep .is-active{
  color: #000;
  background-color: rgb(204,204,204) !important;
}
</style>