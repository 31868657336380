<template>
<div>
    <!-- <el-tooltip class="item" effect="dark" :content="data.name" placement="bottom"> -->
    <el-submenu :class="{videosColor: data.workType==1}" v-if="data.children.length>0&&data.workType!=1" :index="data.workId+'-'+data.workType">
    <template slot="title">
         
      {{data.name}}
    
        </template>
    <DomList v-for="item in data.children" :key="''+item.workId" :data="item">
    </DomList>
    </el-submenu>
    <!-- </el-tooltip>
    <el-tooltip  class="item" effect="dark" :content="data.name" placement="bottom"> -->
    <el-menu-item  v-else  :class="{videosColor: data.workType==1}" :index="data.workId+'-'+data.workType">   
        
      {{data.name}}
      </el-menu-item> 
    <!-- </el-tooltip> -->
    
</div>
    
</template>

<script>
export default {
    name:"DomList",
    props:["data","index"],
    methods:{
     
    }
}
</script>
<style scoped>
.videosColor{
  color: #409EFF !important;
}
</style>